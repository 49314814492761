<template>
  <div class="footer">
    <div
      style="
        display: flex;
        justify-content: space-around;
        height: 30px;
        line-height: 48px;
      "
    >
      <div style="display: flex; justify-content: center">
        <div>
<!--          <img alt="" src="@/assets/images/footer.png" width="25px" />-->
        </div>
        <div style="width: 10px; height: 10px"></div>
        <div>
          <span><a style="color: rgba(0, 0, 0, 0.65);" target="_blank" :href="'https://beian.miit.gov.cn/#/Integrated/index'">{{configdata.sitebeian}}</a></span>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div>
          <img alt="" src="@/assets/images/kefu.png" width="25px" />
        </div>
        <div style="width: 10px; height: 30px"></div>
        <div>
          <span>{{ configdata.maile }}</span>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div>
          <img alt="" src="@/assets/images/iPhone.png" width="25px" />
        </div>
        <div style="width: 10px; height: 10px"></div>
        <div>
          <span>{{ configdata.servicephone }}</span>
        </div>
        <div style="width: 250px; height: 50px"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProGlobalFooter",
  data() {
      return {
          configdata:'',
      }
  },
  components: {},
  created() {
    var configdata =  JSON.parse(localStorage.getItem('configdata'))
    this.configdata = configdata

  },
};
</script>

<style lang="css" scoped>
.footer {
    font-size: 15px;
    font-weight: bold;
  width: 100%;
  height: 100px;
  /*background: #FFFFFF;*/
  position: fixed;
  bottom: 0px;
  padding-top: 20px;
  border-top: 15px solid #f5f7fb;
  background:#FFFFFF url("../../assets/images/foot.png");
  background-repeat: no-repeat;
  background-position: right;
}
</style>

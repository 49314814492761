// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/foot.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.footer[data-v-b34914be] {\r\n    font-size: 15px;\r\n    font-weight: bold;\r\n  width: 100%;\r\n  height: 100px;\r\n  /*background: #FFFFFF;*/\r\n  position: fixed;\r\n  bottom: 0px;\r\n  padding-top: 20px;\r\n  border-top: 15px solid #f5f7fb;\r\n  background:#FFFFFF url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  background-position: right;\n}\r\n", ""]);
// Exports
module.exports = exports;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "box" } }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          [
            _c("NavLeft", {
              attrs: { partInfo: _vm.infos },
              on: { "item-click": _vm.navClick },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("NavTitle", { on: { "title-click": _vm.titleClink } }),
          _c("div", { staticStyle: { width: "100%" } }, [_c("router-view")], 1),
          _c("NavFooter"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-around",
          height: "30px",
          "line-height": "48px",
        },
      },
      [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _c("div"),
            _c("div", { staticStyle: { width: "10px", height: "10px" } }),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.configdata.sitebeian))])]),
          ]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _vm._m(0),
            _c("div", { staticStyle: { width: "10px", height: "30px" } }),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.configdata.maile))])]),
          ]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _vm._m(1),
            _c("div", { staticStyle: { width: "10px", height: "10px" } }),
            _c("div", [
              _c("span", [_vm._v(_vm._s(_vm.configdata.servicephone))]),
            ]),
            _c("div", { staticStyle: { width: "250px", height: "50px" } }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          alt: "",
          src: require("@/assets/images/kefu.png"),
          width: "25px",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          alt: "",
          src: require("@/assets/images/iPhone.png"),
          width: "25px",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }